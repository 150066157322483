@import './common.scss';

.dialog-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(black, 0.45);
    z-index: 100;
    box-sizing: border-box;

    .dialog {
        position: absolute;
        padding: 20px;
        top: 50%;
        transform: translateY(-50%);
        background-color: white;
        border-radius: 8px;
        box-shadow: 0px 8px 26px 0px rgba(0, 0, 0, 0.1);
        border: 1px solid $brand-primary-color;

        .icon-close {
            position: relative;
            z-index: 1;
            float: right;
            font-size: 24px;
            color: $brand-primary-color;

            &:hover {
                color: $brand-primary-dark-color;
                cursor: pointer;
            }
        }

        .dialog-content {
            max-width: 305px;
            position: relative;
            margin: auto;
            letter-spacing: 0.5px;
            text-align: center;

            h6 {
                @include Akrobat-Black;
                margin: 0;
                margin-bottom: 10px;
                font-size: 20px;
                color: $brand-primary-color;
            }

            span {
                display: block;
            }
        }
    }
}

// -----------------------------------------------------------------------------------------------
// Desktop
// -----------------------------------------------------------------------------------------------

@media only screen and (min-width: 801px) {
    .dialog-backdrop {
        .dialog {
            width: 400px;
            left: 0;
            right: 0;
            margin: 0 auto;

            .dialog-content {
                padding: 50px 20px;
            }
        }
    }
}

// -----------------------------------------------------------------------------------------------
// Mobile
// -----------------------------------------------------------------------------------------------

@media only screen and (max-width: 800px) {
    .dialog-backdrop {
        padding: 0 15px;

        .dialog {
            right: 15px;
            left: 15px;

            .dialog-content {
                padding: 30px 20px;
            }
        }
    }
}
