@import './common.scss';

.slideshow {
    position: relative;
    overflow: hidden;

    .background {
        position: absolute;
        height: 100%;
        left: 0;
        right: 0;
        max-height: 400px;
        top: 50%;
        transform: translateY(-50%);
        box-sizing: border-box;
        background-color: $brand-primary-color;
    }

    .icon-left, .icon-right {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        display: block;
        z-index: 1;
        background-color: $brand-secondary-color;
        font-size: 28px;
        text-align: center;
        color: $brand-primary-color;
    }

    .icon-left {
        left: 20px;
        border-radius: 5px;
    }

    .icon-right {
        right: 20px;
        border-radius: 5px;
    }

    .item {
        position: relative;
        display: none;

        &.selected {
            display: block;
        }
    }
}

// -----------------------------------------------------------------------------------------------
// Desktop
// -----------------------------------------------------------------------------------------------

@media only screen and (min-width: 801px) {
    .slideshow {
        .icon-left, .icon-right {
            height: 60px;
            width: 60px;
            line-height: 60px;
            cursor: pointer;

            &:hover {
                color: $brand-primary-dark-color;
            }
        }
    }
}

// -----------------------------------------------------------------------------------------------
// Mobile
// -----------------------------------------------------------------------------------------------

@media only screen and (max-width: 800px) {
    .slideshow {
        .icon-left, .icon-right {
            height: 50px;
            width: 50px;
            line-height: 50px;
        }
    }
}
