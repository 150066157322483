@import './common.scss';

.animal-species {
    background-color: white;

    .centered {
        h1 {
            @include Akrobat-Black;
            margin: 0;
            text-transform: uppercase;
            color: rgba($brand-primary-color, 0.3);
        }

        .animal-outter-wrapper {
            .animal-inner-wrapper {
                display: block;
                background-color: $brand-secondary-color;

                .image-wrapper {
                    @include image169;
                }

                .basic-info {
                    padding: 15px;

                    h6 {
                        @include Akrobat-Black;
                        padding: 0;
                        margin: 0;
                        margin-bottom: 15px;
                        letter-spacing: 0.5px;
                        color: $brand-primary-color;
                    }

                    .english-name {
                        @include Mulish-Bold;
                        display: block;
                        letter-spacing: 0.5px;
                        color: white;
                    }
                }
            }
        }
    }
}

// -----------------------------------------------------------------------------------------------
// Desktop
// -----------------------------------------------------------------------------------------------

@media print, screen and (min-width: 801px) {
    .animal-species {
        margin-top: $header-height;

        .centered {
            padding: 0 ($default-padding - 5px);
            padding-bottom: 80px;

            h1 {
                margin-bottom: 50px;
                letter-spacing: 2.5px;
                font-size: 160px;
            }

            .animal-outter-wrapper {
                width: 33.33%;
                display: inline-block;
                vertical-align: top;
                padding: 5px;
                box-sizing: border-box;

                .animal-inner-wrapper {
                    .basic-info {
                        h6 {
                            height: 75px;
                            font-size: 36px;
                            line-height: 38px;
                        }

                        .english-name {
                            height: 45px;
                            line-height: 20px;
                            font-size: 18px;
                        }
                    }

                    &:hover {
                        background-color: #3F352C;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 980px) {
    .animal-species {
        .centered {
            .animal-outter-wrapper {
                width: 50%;
            }
        }
    }
}

// -----------------------------------------------------------------------------------------------
// Mobile
// -----------------------------------------------------------------------------------------------

@media only screen and (max-width: 800px) {
    .animal-species {
        margin-top: $header-height-mobile;

        .centered {
            padding: 30px ($default-padding-mobile - 5px);
            padding-bottom: 60px;

            h1 {
                margin-bottom: 30px;
                letter-spacing: 1px;
                font-size: 65px;
            }

            .animal-outter-wrapper {
                width: 50%;
                display: inline-block;
                padding: 5px;
                box-sizing: border-box;

                .animal-inner-wrapper {
                    .basic-info {
                        h6 {
                            min-height: 50px;
                            font-size: 26px;
                        }

                        .english-name {
                            min-height: 32px;
                            font-size: 14px;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 600px) {
    .animal-species {
        .centered {
            padding: 30px 0;

            h1 {
                padding:  0 $default-padding-mobile;
            }

            .animal-outter-wrapper {
                width: 100%;
                display: block;
                padding: 10px 0;
            }
        }
    }
}
