@import './common.scss';

.animal-data-sheet {
    background-color: white;

    .icon-info-wrapper {
        margin-bottom: 20px;
        line-height: 23px;

        .icon {
            width: 30px;
            display: inline-block;
            vertical-align: top;
            text-align: center;
            font-size: 26px;
        }

        .title-value-wrapper {
            width: 100%;
            display: inline-block;
            vertical-align: top;
            margin-left: -30px;
            padding-left: 45px;
            box-sizing: border-box;
        }
    }

    .name-image-wrapper {
        background-color: $brand-secondary-color;
        color: white;

        .image-wrapper {
            @include image169;
            min-height: 210px;
        }

        .name-details {
            box-sizing: border-box;
        }

        h5 {
            @include Akrobat-Black;
            margin: 0;
            margin-bottom: 15px;
            line-height: 42px;
            letter-spacing: 0.5px;
            font-size: 42px;
            color: $brand-primary-color;
        }

        .english-name {
            @include Mulish-Bold;
            display: block;
            margin-bottom: 10px;
            line-height: 25px;
            letter-spacing: 0.5px;
            font-size: 20px;
        }

        .scientific-name {
            display: block;
            letter-spacing: 0.5px;
            line-height: 25px;
            font-style: italic;
            font-size: 20px;
        }
    }

    .detailed-info-wrapper {
        font-size: 18px;
        background-color: $brand-primary-color;
        color: white;
    }

    .risk-info-wrapper {
        background-color: $brand-secondary-color;
        color: #66594E;

        .actual-situation {
            display: block;
            letter-spacing: 0.5px;
            font-size: 18px;
            color: white;
        }

        .risk-element {
            margin-top: 30px;

            .risk-type {
                @include Mulish-Medium;
                text-transform: uppercase;
                letter-spacing: 0.5px;
                font-size: 18px;
                color: $brand-primary-color;
            }

            .risk-options {
                .risk-option {
                    width: 140px;
                    display: inline-block;
                    vertical-align: top;
                    margin-top: 15px;

                    .icon, .title {
                        display: block;
                    }

                    .icon {
                        margin-bottom: 5px;
                        font-size: 26px;
                    }

                    .title {
                        @include Mulish-Medium;
                        max-width: 100px;
                        letter-spacing: 0.5px;
                        font-size: 12px;
                    }

                    &.active {
                        .icon, .title {
                            color: $brand-primary-color;
                        }
                    }
                }
            }
        }
    }

    .image-gallery, .iconography {
        h6 {
            @include Akrobat-Black;
            padding: 0;
            margin: 0;
            margin-bottom: 20px;
            letter-spacing: 0.5px;
            font-size: 36px;
            color: $brand-primary-color;
        }
    }

    .image-gallery {
        padding: 50px 0;

        h6 {
            text-align: center;
        }

        .slideshow {
            padding: 0 $default-padding-mobile;
        }

        .image-wrapper {
            max-width: 815px;
            margin: auto;
        }

        .image {
            @include image169;
            max-height: 540px;

            > div {
                background-position: center;
            }
        }
    }

    .iconography {
        padding: 50px 0;

        .icon-info-wrapper {
            .icon {
                color: $brand-primary-color;
            }

            .value {
                max-width: 200px;
            }
        }

        .icon-elephant {
            color: rgba($brand-primary-color, 0.2);
        }
    }
}

// -----------------------------------------------------------------------------------------------
// Desktop
// -----------------------------------------------------------------------------------------------

@media print, screen and (min-width: 801px) {
    .animal-data-sheet {
        padding: 50px 0;
        margin-top: $header-height;

        .name-image-wrapper {
            .image-wrapper {
                width: 40%;
                display: inline-block;
                vertical-align: top;
            }

            .name-details {
                width: 60%;
                display: inline-block;
                vertical-align: top;
                padding: 30px;
            }
        }

        .detailed-info-wrapper {
            padding: 30px;

            .icon-info-wrapper {
                &.identification {
                    margin-bottom: 70px;
                }
            }

            .left-column {
                width: 40%;
                display: inline-block;
                vertical-align: top;
                padding-right: 10px;
                box-sizing: border-box;
            }

            .right-column {
                width: 60%;
                display: inline-block;
                vertical-align: top;
            }
        }

        .risk-info-wrapper {
            padding: 30px;
        }

        .iconography {
            .icon-elephant {
                position: absolute;
                top: -80px;
                right: 0;
                font-size: 400px;
            }
        }
    }
}

// -----------------------------------------------------------------------------------------------
// Mobile
// -----------------------------------------------------------------------------------------------

@media only screen and (max-width: 800px) {
    .animal-data-sheet {
        padding: 30px 0;
        margin-top: $header-height-mobile;

        .centered {
            padding: 0;
        }

        .name-image-wrapper {
            .name-details {
                padding: 30px $default-padding-mobile;
            }
        }

        .detailed-info-wrapper {
            padding: 30px $default-padding-mobile;
        }

        .risk-info-wrapper {
            padding: 30px $default-padding-mobile;
        }

        .image-gallery {
            padding-bottom: 0;
        }

        .iconography {
            padding: 30px $default-padding-mobile;

            .icon-elephant {
                display: block;
                text-align: center;
                font-size: 240px;
            }
        }
    }
}
