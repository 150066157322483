@import '~include-media/dist/_include-media.scss';

$min-width: 800px;
$max-width: 1280px;
$default-padding: 40px;
$default-padding-mobile: 20px;

$header-height: 100px;
$header-height-mobile: 80px;

$brand-primary-color: #FF8300;
$brand-primary-dark-color: #F07400;
$brand-secondary-color: #30261D;

$red: #E10000;
$gray: #8A8988;
$light-gray: #CECECE;
$lighter-gray: #F7F7F7;

// -----------------------------------------------------------------------------------------------
// Fonts
// -----------------------------------------------------------------------------------------------

@mixin Akrobat-Regular {
    font-family: 'Akrobat', sans-serif;
    font-weight: 500;
    font-style: normal;
}

@mixin Akrobat-Bold {
    font-family: 'Akrobat Bold', sans-serif;
    font-weight: 700;
    font-style: normal;
}

@mixin Akrobat-Black {
    font-family: 'Akrobat Black', sans-serif;
    font-weight: 900;
    font-style: normal;
}

@mixin Mulish-Regular {
    font-family: 'Mulish', sans-serif;
    font-style: normal;
    font-weight: 400;
}

@mixin Mulish-Medium {
    font-family: 'Mulish', sans-serif;
    font-style: normal;
    font-weight: 500;
}

@mixin Mulish-Bold {
    font-family: 'Mulish', sans-serif;
    font-style: normal;
    font-weight: 700;
}

// -----------------------------------------------------------------------------------------------
// Mixins
// -----------------------------------------------------------------------------------------------

@import './mixins';
