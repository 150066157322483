@import './common.scss';

.contact-form {
    position: relative;
    overflow: hidden;

    form {
        max-width: 875px;
        border-radius: 8px;
        background-color: white;
        font-size: 16px;

        .required-text {
            @include Mulish-Regular;
            letter-spacing: 0.5px;
            color: $gray;
        }

        .input-wrapper {
            position: relative;
            box-sizing: border-box;
        }

        label {
            @include Mulish-Bold;
            display: block;
            letter-spacing: 0.5px;
            margin-bottom: 10px;
            line-height: 24px;
        }

        input, textarea {
            width: 100%;
            padding: 0 20px;
            box-sizing: border-box;
            background-color: transparent;
            border: 1px solid $gray;
            border-radius: 5px;
            letter-spacing: 0.5px;
            resize: none;
            outline: none;

            &::placeholder {
                color: $gray;
            }

            &.invalid {
                border-color: $red;
            }

            &:focus {
                border-color: $brand-secondary-color;
            }

            &:disabled {
                border-color: $light-gray;
                background-color: $lighter-gray;
                color: $gray;
            }
        }

        input {
            height: 45px;
        }

        textarea {
            height: 145px;
            padding: 15px 20px;
        }

        button {
            @include Mulish-Bold;
            height: 50px;
            padding: 0;
            border: none;
            border-radius: 8px;
            background-color: $brand-primary-color;
            line-height: 50px;
            letter-spacing: 2px;
            font-size: 18px;
            color: white;

            &:disabled {
                background-color: $light-gray;
            }
        }
    }
}

// -----------------------------------------------------------------------------------------------
// Desktop
// -----------------------------------------------------------------------------------------------

@media only screen and (min-width: 801px) {
    .contact-form {
        .centered {
            box-sizing: border-box;
        }

        form {
            height: 100%;
            padding: 60px 75px;
            padding-bottom: 90px;

            .required-text {
                padding: 0 10px;
            }

            .input-wrapper {
                width: 50%;
                display: inline-block;
                padding: 0 10px;
                padding-bottom: 20px;
                box-sizing: border-box;

                &.large {
                    width: 100%;
                }
            }

            button {
                width: 200px;
                float: right;

                &:hover {
                    background-color: $brand-primary-dark-color;
                    cursor: pointer;

                    &:disabled {
                        background-color: $gray;
                        cursor: default;
                    }
                }
            }
        }
    }
}

// -----------------------------------------------------------------------------------------------
// Mobile
// -----------------------------------------------------------------------------------------------

@media only screen and (max-width: 800px) {
    .contact-form {
        .centered {
            padding: 0;
        }

        form {
            padding: 40px $default-padding-mobile;

            .input-wrapper {
                padding: 15px 0;
                box-sizing: border-box;
            }

            button {
                width: 100%;
                margin-top: 20px;
            }
        }
    }
}
