@font-face {
    font-family: 'pastora-icons';
    src: url('../fonts/pastora-icons.ttf?5qewot') format('truetype'),
    url('../fonts/pastora-icons.woff?5qewot') format('woff'),
    url('../fonts/pastora-icons.svg?5qewot#pastora-icons') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'pastora-icons' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    // line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-left:before {
    content: "\e928";
}
.icon-right:before {
    content: "\e929";
}
.icon-appendix-1:before {
    content: "\e91d";
}
.icon-appendix-2:before {
    content: "\e91e";
}
.icon-appendix-3:before {
    content: "\e91f";
}
.icon-critically-endangered:before {
    content: "\e920";
}
.icon-data-deficient:before {
    content: "\e921";
}
.icon-endangered:before {
    content: "\e922";
}
.icon-extinct-in-the-wild:before {
    content: "\e923";
}
.icon-extinct2:before {
    content: "\e924";
}
.icon-least-concern:before {
    content: "\e925";
}
.icon-near-threatened:before {
    content: "\e926";
}
.icon-vulnerable:before {
    content: "\e927";
}
.icon-back:before {
    content: "\e90e";
}
.icon-distribution:before {
    content: "\e90f";
}
.icon-elephant:before {
    content: "\e910";
}
.icon-Extinct:before {
    content: "\e911";
}
.icon-fe:before {
    content: "\e912";
}
.icon-feeding:before {
    content: "\e913";
}
.icon-gestation:before {
    content: "\e914";
}
.icon-habitat:before {
    content: "\e915";
}
.icon-id:before {
    content: "\e916";
}
.icon-in-danger:before {
    content: "\e917";
}
.icon-in:before {
    content: "\e918";
}
.icon-info:before {
    content: "\e919";
}
.icon-no-risk:before {
    content: "\e91a";
}
.icon-special-protection:before {
    content: "\e91b";
}
.icon-threatened:before {
    content: "\e91c";
}
.icon-menu:before {
    content: "\e90c";
}
.icon-close:before {
    content: "\e90d";
}
.icon-clock:before {
    content: "\e900";
}
.icon-down:before {
    content: "\e901";
}
.icon-email:before {
    content: "\e902";
}
.icon-location:before {
    content: "\e903";
}
.icon-love:before {
    content: "\e904";
}
.icon-postcard:before {
    content: "\e905";
}
.icon-prohibited:before {
    content: "\e906";
}
.icon-rules:before {
    content: "\e907";
}
.icon-summer:before {
    content: "\e908";
}
.icon-telephone:before {
    content: "\e909";
}
.icon-up:before {
    content: "\e90a";
}
.icon-winter:before {
    content: "\e90b";
}
