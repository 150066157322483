@import './common.scss';

header {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 5;
    background-color: transparent;
    box-sizing: border-box;

    &.white-header {
        background-color: white;
        border-bottom: 1px solid $brand-primary-color;

        ul {
            li {
                a {
                    color: $brand-secondary-color;
                }
            }
        }
    }

    .centered {
        height: 100%;
    }

    ul {
        padding: 0;
        margin: 0;
        list-style: none;

        li {
            @include Mulish-Bold;
            letter-spacing: 1px;
        }
    }
}

// -----------------------------------------------------------------------------------------------
// Desktop
// -----------------------------------------------------------------------------------------------

@media print, screen and (min-width: 801px) {
    header {
        height: $header-height;
        line-height: $header-height;

        img {
            height: 50px;
            margin: 25px 0;
        }

        .menu {
            display: none;
        }

        ul {
            float: right;

            li {
                display: inline-block;
                margin-left: 40px;

                a {
                    color: white;
                }
            }
        }
    }
}

// -----------------------------------------------------------------------------------------------
// Mobile
// -----------------------------------------------------------------------------------------------

@media only screen and (max-width: 800px) {
    header {
        height: $header-height-mobile;
        line-height: $header-height-mobile;

        &.white-header {
            .menu {
                color: $brand-secondary-color;
            }
        }

        .menu {
            width: 50px;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            display: inline-block;
            text-align: center;
            font-size: 26px;
            color: white;
        }

        img {
            height: 30px;
            margin: 25px 0;
        }

        ul {
            min-height: 400px;
            position: absolute;
            top: $header-height-mobile;
            right: 0;
            left: 0;
            z-index: 10;
            background-color: white;
            border-bottom: 1px solid $brand-primary-color;
            text-align: center;

            &.hidden {
                display: none;
            }

            li {
                display: block;
                margin: 45px 0;
                line-height: normal;

                a {
                    color: $brand-secondary-color !important;
                }
            }
        }
    }
}
