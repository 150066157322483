@import './common.scss';

footer {
    width: 100%;
    position: relative;
    padding: 40px 0;
    background-color: $brand-secondary-color;

    .icon-up {
        width: 50px;
        height: 50px;
        position: absolute;
        display: block;
        line-height: 50px;
        border-radius: 8px;
        border: 1px solid $brand-primary-color;
        background-color: $brand-secondary-color;
        text-align: center;
        font-size: 30px;
        color: $brand-primary-color;
    }

    .footer-section {
        font-size: 14px;
        color: white;

        h6 {
            letter-spacing: 2px;
            font-size: 11px;
        }

        &.logo {
            img {
                max-width: 108px;
            }
        }

        a {
            color: $brand-primary-color;

            &:hover {
                color: $brand-primary-dark-color;
            }
        }

        .section-info {
            letter-spacing: 0.5px;

            .icon-telephone, .icon-email, .icon-location {
                font-size: 28px;
                color: $brand-primary-color;
            }

            .icon-telephone, .icon-email {
                vertical-align: middle;
            }

            .icon-location {
                vertical-align: top;
            }

            .address-wrapper {
                display: inline-block;
                vertical-align: top;
            }

            &.social-wrapper {
                .icon {
                    margin-right: 10px;
                    font-size: 28px;
                }
            }
        }

        p {
            margin: 0;
            line-height: 18px;
        }

        &.developed-by {
            color: $gray;

            .icon-love {
                display: inline-block;
                vertical-align: middle;
                font-size: 18px;
                color: $red;
            }

            a {
                @include Mulish-Bold;
            }
        }
    }
}

// -----------------------------------------------------------------------------------------------
// Desktop
// -----------------------------------------------------------------------------------------------

@media print, screen and (min-width: 801px) {
    footer {
        .icon-up {
            top: -75px;
            right: $default-padding;

            &:hover {
                border-color: $brand-primary-dark-color;
                color: $brand-primary-dark-color;
                cursor: pointer;
            }
        }

        .centered {
            height: 120px;
            margin: 0 auto;
            text-align: justify;
            text-justify: distribute-all-lines;

            // This is needed to correctly justify all the columns.
            &::after {
                width: 100%;
                display: inline-block;
                font-size: 0;
                line-height: 0;
                content: '';
            }

            .footer-section {
                display: inline-block;
                vertical-align: top;
                margin-top: 10px;
                margin-right: 15px;
                margin-left: 0;
                text-align: left;

                .section-info {
                    height: 120px;

                    .icon-telephone, .icon-email, .icon-location {
                        display: inline-block;
                        margin-right: 10px;
                    }

                    &.social-wrapper {
                        .icon {
                            &:hover {
                                color: $brand-primary-dark-color;
                            }
                        }
                    }
                }

                &.developed-by {
                    p {
                        margin-top: 65px;
                    }
                }
            }
        }

        a:hover {
            color: $brand-primary-dark-color;
        }
    }
}

// -----------------------------------------------------------------------------------------------
// Mobile
// -----------------------------------------------------------------------------------------------

@media only screen and (max-width: 800px) {
    footer  {
        .footer-section {
            margin-top: 30px;
            text-align: center;

            .section-info {
                .icon-telephone, .icon-email, .icon-location {
                    display: block;
                    margin-bottom: 10px;
                }

                .email-wrapper {
                    margin-top: 30px;
                }

                &.social-wrapper {
                    margin-top: 50px;
                }
            }

            &.developed-by {
                p {
                    margin-top: 30px;
                }
            }
        }

        .icon-up {
            top: -105px;
            right: $default-padding;
        }
    }
}
