@import './common.scss';
@import './icons.scss';
@import '~normalize.css/normalize.css';

html,
body {
    width: 100%;
    height: 100%;
}

body {
    @include Mulish-Regular;
    background-color: $brand-primary-color;
    font-size: 16px;
    color: $brand-secondary-color;
    -webkit-overflow-scrolling: touch;
}

#root {
    width: 100%;
    height: 100%;
}

a {
    text-decoration: none;
}

.centered {
    position: relative;
    margin: 0 auto;
    box-sizing: border-box;
}

main {
    height: 100%;

    .hero-wrapper {
        @include image169;
        min-height: 500px;
        max-height: 700px;
        position: relative;
        overflow: hidden;

        p {
            @include Akrobat-Bold;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            margin: 0;
            letter-spacing: 0.5px;
            color: white;

            span {
                @include Akrobat-Black;
            }
        }
    }

    section {
        @include Mulish-Medium;
        position: relative;
        background-color: white;
        font-size: 18px;

        h1 {
            @include Akrobat-Black;
            margin: 0;
            text-transform: uppercase;
            color: rgba($brand-primary-color, 0.3);
        }

        h2 {
            @include Akrobat-Black;
            margin-top: 0;
            color: $brand-primary-color;
        }

        h3 {
            @include Akrobat-Black;
            margin: 0;
            color: $brand-primary-color;
        }

        &.about-section {
            padding-bottom: 0;

            p {
                line-height: 23px;
                text-align: justify;
            }

            .about-info-wrapper {
                margin-bottom: 45px;
            }

            .species-link {
                @include Mulish-Bold;
                max-width: 600px;
                display: block;
                margin: 80px auto;
                background-color: $brand-primary-color;
                border-radius: 15px;
                text-align: center;
                font-size: 22px;
                color: white;

                &:hover {
                    background-color: $brand-primary-dark-color;
                }
            }

            .about-footer {
                @include image169;
            }
        }

        &.opening-hours-section,
        &.location-section {
            background-color: $brand-secondary-color;
        }

        &.opening-hours-section {
            text-align: center;
            color: white;

            .icon-clock {
                position: absolute;
                font-size: 300px;
                color: rgba($brand-primary-color, 0.1);
            }

            h1 {
                margin-bottom: 60px;
            }

            span {
                @include Mulish-Bold;
                font-size: 24px;
                letter-spacing: 0.5px;
                display: block;
            }

            h6 {
                @include Akrobat-Black;
                margin-top: 0;
                margin-bottom: 40px;
                letter-spacing: 0.8px;
                font-size: 30px;
                color: $brand-primary-color;
            }

            h5 {
                @include Akrobat-Black;
                margin-top: 10;
                margin-bottom: 20px;
                letter-spacing: 0.8px;
                font-size: 20px;
                color: $brand-primary-color;
            }

            .opening-hours-wrapper {
                margin-bottom: 40px;

                .icon {
                    font-size: 50px;
                }

                .hours {
                    @include Mulish-Regular;
                    margin-top: 5px;
                    font-size: 18px;
                    letter-spacing: 1px;
                }
            }
        }

        &.prices-section {
            h3 {
                line-height: 42px;
                letter-spacing: 0.5px;
            }

            .price-caption {
                display: block;
                margin-top: 10px;
                margin-bottom: 30px;
            }

            .free-entrance-caption {
                display: block;
            }

            .image-wrapper {
                width: 100%;

                img {
                    width: 100%;
                    max-width: 500px;
                }
            }
        }

        &.regulation-section {
            background-color: $brand-primary-color;
            color: white;

            .icon-rules {
                position: absolute;
                font-size: 350px;
                color: rgba(white, 0.15);
            }

            h1 {
                color: rgba(white, 0.3);
            }

            h2 {
                color: white;
            }

            .prohibited-caption {
                @include Mulish-Bold;
                letter-spacing: 0.5px;
            }

            ul {
                padding: 0;
                margin: 0;
                margin-top: 30px;
                list-style: none;
                letter-spacing: 0.5px;

                li {
                    margin-bottom: 15px;

                    span {
                        display: inline-block;
                        vertical-align: top;
                    }

                    .rule-text {
                        width: 100%;
                        padding-left: 40px;
                        margin-left: -30px;
                        box-sizing: border-box;
                    }
                }
            }
        }

        &.location-section {
            .location-wrapper {
                .location-address-wrapper {
                    position: relative;

                    .icon-location {
                        font-size: 34px;
                        color: $brand-primary-color;
                    }

                    .address {
                        color: white;

                        span {
                            display: block;
                        }
                    }
                }

                a {
                    display: inline-block;
                    margin-top: 30px;
                    color: $brand-primary-color;

                    &:hover {
                        color: $brand-primary-dark-color;
                    }
                }

                .image-wrapper {
                    width: 100%;

                    img {
                        width: 100%;
                        max-width: 650px;
                    }
                }
            }
        }

        &.contact-section {
            .contact-info-wrapper {
                margin-bottom: 50px;

                &:last-child {
                    margin-bottom: 0;
                }

                h6 {
                    @include Akrobat-Black;
                    margin-top: 0;
                    margin-bottom: 15px;
                    font-size: 36px;
                    letter-spacing: 0.5px;
                    color: $brand-primary-color;
                }

                span {
                    display: inline-block;
                    vertical-align: middle;
                }

                .icon {
                    margin-right: 20px;
                    font-size: 24px;
                    color: $brand-primary-color;
                }

                a {
                    color: $brand-secondary-color;
                }
            }

            .image-wrapper {
                img {
                    max-width: 100%;
                }
            }
        }

        &.contact-form-section {
            background-color: $brand-primary-color;

            .icon-postcard {
                font-size: 230px;
                color: rgba(white, 0.15);
            }

            h3 {
                @include Akrobat-Black;
                margin: 0;
                color: white;
            }
        }
    }
}

// -----------------------------------------------------------------------------------------------
// Desktop
// -----------------------------------------------------------------------------------------------

@media print,
screen and (min-width: 801px) {
    .centered {
        min-width: $min-width;
        max-width: $max-width;
        padding: 0 $default-padding;
    }

    .hero-wrapper {
        .hero-img {
            background-image: url('../assets/hero.jpg');
        }

        p {
            width: 375px;
            line-height: 42px;
            font-size: 38px;
        }
    }

    section {
        padding: 85px 0;

        h1 {
            letter-spacing: 2.5px;
            font-size: 160px;
        }

        h2 {
            margin-bottom: 60px;
            letter-spacing: 0.8px;
            font-size: 60px;
        }

        &.about-section {
            h1 {
                line-height: 115px;
            }

            .about-info-wrapper {
                &.right-text {
                    .text-wrapper {
                        padding-right: 0;
                        padding-left: 40px;
                    }
                }

                .text-wrapper {
                    width: 65%;
                    display: inline-block;
                    vertical-align: top;
                    box-sizing: border-box;
                    padding-right: 40px;
                }
            }

            .about-image-wrapper {
                width: 35%;
                display: inline-block;
                vertical-align: top;

                &.mobile {
                    display: none;
                }

                img {
                    width: 100%;
                    max-height: 490px;
                }
            }

            .species-link {
                height: 75px;
                padding: 0 55px;
                line-height: 75px;

                &:hover {
                    background-color: $brand-primary-dark-color;
                }
            }

            .about-footer {
                height: 400px;
            }
        }

        &.opening-hours-section {
            .icon-clock {
                left: -50px;
                bottom: -90px;
                font-size: 300px;
            }

            .opening-hours-wrapper {
                width: 300px;
                margin: auto;
            }
        }

        &.prices-section {
            h1 {
                margin-bottom: 60px;
            }

            h3 {
                font-size: 42px;
            }

            .free-entrance-caption {
                margin-top: 60px;
            }

            .prices-wrapper {
                width: 400px;
                display: inline-block;
                vertical-align: top;
            }

            .image-wrapper {
                display: inline-block;
                vertical-align: top;
                padding-left: 400px;
                margin-left: -400px;
                margin-top: -30px;
                box-sizing: border-box;
                text-align: right;
            }
        }

        &.regulation-section {
            h1 {
                line-height: 115px;
            }

            .icon-rules {
                right: 50px;
                bottom: -80px;
            }
        }

        &.location-section {
            h1 {
                margin-bottom: 60px;
            }

            .location-wrapper {
                .location-address-wrapper {
                    width: 370px;
                    display: inline-block;
                    vertical-align: top;

                    .icon-location {
                        display: inline-block;
                        vertical-align: top;
                        margin-right: 20px;
                    }

                    .address {
                        display: inline-block;
                        vertical-align: top;
                    }
                }

                .image-wrapper {
                    display: inline-block;
                    vertical-align: top;
                    padding-left: 370px;
                    margin-left: -370px;
                    margin-top: -60px;
                    box-sizing: border-box;
                    text-align: right;

                    &.mobile {
                        display: none;
                    }
                }
            }
        }

        &.contact-section {
            h1 {
                margin-bottom: 60px;
            }

            .contact-info-outer-wrapper {
                width: 65%;
                display: inline-block;
                vertical-align: top;
            }

            .image-wrapper {
                width: 35%;
                display: inline-block;
                vertical-align: top;
                margin-top: -30px;
                padding-right: 30px;
                box-sizing: border-box;

                img {
                    max-height: 300px;
                }
            }
        }

        &.contact-form-section {
            h3 {
                width: 245px;
                display: inline-block;
                vertical-align: top;
                line-height: 42px;
                font-size: 60px;
            }

            .contact-form {
                width: 100%;
                display: inline-block;
                box-sizing: border-box;
                padding-left: 285px;
                margin-left: -245px;
            }

            .icon-postcard {
                position: absolute;
                left: $default-padding;
                bottom: 0;
            }
        }
    }
}

// -----------------------------------------------------------------------------------------------
// Mobile
// -----------------------------------------------------------------------------------------------

@media only screen and (max-width: 800px) {
    .centered {
        padding: 0 $default-padding-mobile;
    }

    .hero-wrapper {
        .hero-img {
            background-image: url('../assets/hero-mobile.jpg');
        }

        p {
            width: 242px;
            line-height: 28px;
            font-size: 24px;
        }
    }

    section {
        padding: 50px 0;

        h1 {
            letter-spacing: 1px;
            font-size: 65px;
        }

        h2 {
            margin-bottom: 45px;
            font-size: 30px;
        }

        &.about-section {
            .text-wrapper {
                margin-bottom: 30px;
            }

            .about-footer {
                height: 115px;

                >div {
                    background-size: contain;
                    background-position: top !important;
                }
            }

            .about-image-wrapper {
                width: 100%;
                max-width: 420px;
                margin: auto;

                &.desktop {
                    display: none;
                }

                img {
                    width: 100%;
                    max-height: 490px;
                }
            }

            .species-link {
                padding: 40px 20px;

                span {
                    max-width: 300px;
                    display: block;
                    margin: auto;
                }
            }
        }

        &.opening-hours-section {
            padding-bottom: 300px;

            .icon-clock {
                left: 50%;
                bottom: -120px;
                transform: translateX(-50%);
            }
        }

        &.prices-section {
            h1 {
                margin-bottom: 45px;
                text-align: center;
            }

            h3 {
                font-size: 30px;
            }

            .free-entrance-caption {
                display: block;
                margin-top: 45px;
            }

            .image-wrapper {
                max-width: 420px;
                margin: auto;
                margin-top: 45px;
            }
        }

        &.regulation-section {
            padding-bottom: 300px;

            .icon-rules {
                left: 50%;
                bottom: -120px;
                transform: translateX(-50%);
            }
        }

        &.location-section {
            h1 {
                margin-bottom: 45px;
            }

            .location-wrapper {
                text-align: center;

                .location-address-wrapper {
                    .icon-location {
                        display: block;
                        margin-bottom: 20px;
                    }
                }

                .image-wrapper {
                    margin-bottom: 30px;

                    &.desktop {
                        display: none;
                    }
                }
            }
        }

        &.contact-section {
            h1 {
                margin-bottom: 45px;
            }

            .image-wrapper {
                max-width: 450px;
                margin: auto;
                margin-bottom: 50px;
            }
        }

        &.contact-form-section {
            padding-bottom: 0;

            h3 {
                line-height: 36px;
                font-size: 30px;
                color: white;
            }

            .contact-form {
                margin: 50px -20px;
                margin-bottom: 0;
            }

            .icon-postcard {
                display: block;
                text-align: center;
            }
        }
    }
}
