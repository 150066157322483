@mixin ellipsis-text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

@mixin image169 {
    position: relative;

    &::before {
        display: block;
        content: '';
        width: 100%;
        padding-top: 56.25%;
    }

    > img, > div, > video {
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-size: cover;
        background-position: center;
    }
}

@mixin imageSq {
    @include image169;

    &::before {
        padding-top: 100%;
    }
}
